<template>
  <div id="user-list">
    <el-row v-if="this.proxy.proxyFlag" class="stats-row" type="flex" align="middle">
      <el-col>
        <span class="red_span"><p>代理商：{{proxy.email}} <el-divider direction="vertical"></el-divider> 账户余额：{{proxy.amount}} <el-divider direction="vertical"></el-divider> 返点：{{proxy.rate}}</p></span>
      </el-col>
    </el-row>
    <el-divider v-if="this.proxy.proxyFlag"></el-divider>
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item v-if="!this.proxy.proxyFlag" label="代理ID">
        <el-input v-model="form.parentId" placeholder="代理ID"></el-input>
      </el-form-item>
      <el-form-item label="ID">
        <el-input v-model="form.id" placeholder="ID"></el-input>
      </el-form-item>
      <el-form-item label="邮箱">
        <el-input v-model="form.email" placeholder="邮箱"></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="ctRange" type="datetimerange" :picker-options="pickerOptions"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right"
                        format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="loadList">查询</el-button>
      </el-form-item>
    </el-form>

    <el-row class="stats-row" type="flex" align="middle">
      <div class="compact-stats">
        <span class="stat-item">
          <span class="label">线下总充值</span>
          <span class="value">{{ proxy.totalRecharge }}</span>
        </span>
        <span class="divider">/</span>
          <span class="stat-item">
          <span class="label">线下总消费</span>
          <span class="value">{{ proxy.totalConsume }}</span>
        </span>
<!--        <span v-if="this.proxy.proxyFlag" class="divider">/</span>-->
<!--        <span v-if="this.proxy.proxyFlag" class="stat-item">-->
<!--          <span class="label">可得返点</span>-->
<!--          <span class="value">{{ (proxy.totalConsume * proxy.rate).toFixed(0) }}</span>-->
<!--        </span>-->
      </div>
    </el-row>

    <el-table :data="computedList" border>
      <el-table-column prop="id" label="ID" width="50">
      </el-table-column>
      <el-table-column prop="email" label="邮箱">
      </el-table-column>
      <el-table-column prop="totalRecharge" label="总充值">
      </el-table-column>
      <el-table-column prop="totalConsume" label="总消费">
      </el-table-column>
      <el-table-column label="余额">
        <template v-slot="row">
          <el-popover placement="left" width="200" trigger="hover">
            <el-descriptions class="margin-top" column="1" border>
              <el-descriptions-item label="可提">
                {{ row.row.freeAmount }}
              </el-descriptions-item>
              <el-descriptions-item label="不可提">
                {{ row.row.limitAmount }}
              </el-descriptions-item>
            </el-descriptions>
            <span slot="reference">{{ row.row.totalAmount }}</span>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="row">
          <el-button @click="openEditDialog(row.row)">用户充值</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button @click="goFR(row.row.id)">资金记录</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="editDialogVisible" width="500px" title="用户充值">
      <el-form>
        <span>用户：{{oprAmountForm.email}}</span>
        <el-form-item label="增加不可提金额">
          <el-input v-model="oprAmountForm.limitAmount"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="submitEdit">确定</el-button>
        <el-button @click="editDialogVisible = false">取消</el-button>
      </span>
    </el-dialog>

    <el-pagination style="text-align: center" :page-count="page.totalPage" :current-page="form.pageNo"
                   layout="prev, pager, next" @current-change="changePage">
    </el-pagination>
  </div>
</template>
<script>
import {list, stat, oprAmount, getProxyInfo} from './proxyopr'
import _ from 'lodash';

export default {
  name: "ProxyUserList",
  data() {
    return {
      editDialogVisible: false,
      proxy: {
        email:"",
        amount:0,
        rate:0,
        totalRecharge: 0,//下线总充值（依据条件）
        totalConsume: 0,//下线总消费（依据条件）
        proxyFlag:false,
      },

      form: {
        pageNo: 1,
        pageSize: 20,
        parentId: null,
        id: null,
        email: null,
        ctStart: null,
        ctEnd: null,
      },

      oprAmountForm: {
        id:null,
        userId: null,
        limitAmount: 0,
        fundType: "SYS_IN",
      },

      ctRange: [],

      page: {
        list: null,
        totalPage: null,
      },
    };
  },

  methods: {
    oprAmount,

    loadList() {
      if(this.ctRange != null && this.ctRange.length > 0){
        this.form.ctStart = this.ctRange[0];
        this.form.ctEnd = this.ctRange[1];
      }
      list(this.form).then((data) => {
        this.page = data;
      });
      this.statDataReset();
      stat(this.form).then((data) => {
        this.proxy.totalRecharge = data.totalRecharge;
        this.proxy.totalConsume = data.totalConsume;
      });
    },

    statDataReset(){
      this.proxy.totalRecharge = 0;
      this.proxy.totalConsume = 0;
    },

    loadProxyInfo() {
      getProxyInfo().then((data) => {
        this.proxy.email = data.email;
        this.proxy.amount = data.totalAmount;
        this.proxy.rate = data.rate;
        this.proxy.proxyFlag = data.proxy;
      });
    },

    goFR(userId) {
      this.$router.push({
        path: '/proxy-userfr-list?userId=' + userId,
      });
    },

    openEditDialog(row) {
      const filteredRow = _.omit(row, ['fundType', 'limitAmount']);
      this.oprAmountForm = { ...this.oprAmountForm,...filteredRow};
      this.editDialogVisible = true;
    },

    submitEdit() {
      oprAmount(this.oprAmountForm).then((data) => {
        const index = this.page.list.findIndex(item => item.id === this.oprAmountForm.id);
        const oldData = this.page.list[index];
        this.$set(this.page.list, index, {
          ...oldData,
          ...this.oprAmountForm,
          limitAmount:Number(oldData.limitAmount)+Number(this.oprAmountForm.limitAmount),
          totalAmount: Number(this.oprAmountForm.freeAmount) + Number(oldData.limitAmount) + Number(this.oprAmountForm.limitAmount)
        });
        this.proxy.amount -= this.oprAmountForm.limitAmount;
        this.editDialogVisible = false;
      });
    },

    changePage(val) {
      this.form.pageNo = val;
      this.loadList();
    },
  },



  computed: {
    computedList() {
      if(this.page.list==null){return;}
      return this.page.list.map(item => ({
        ...item,
        totalAmount: item.freeAmount + item.limitAmount
      }));
    }
  },

  created() {
    this.loadList();
    this.loadProxyInfo();
  },

};
</script>

<style scoped>
.red_span {
  color: #e44e4e;
  font-weight: bold;
}
.compact-stats {
  display: flex;
  align-items: center;
  gap: 8px;

  /* .stat-item {
    display: inline-flex;
    align-items: baseline;

    .label {
      color: #666;
      margin-right: 4px;
      &::after {
        content: ":";
      }
    }

    .value {
      color: #1890ff;
      font-weight: 500;
    }
  }

  .divider {
    color: #ddd;
    padding: 0 4px;
  } */
}
</style>