import axios from "axios";
import msg from "../../util/msg.js";

export function list(param) {
  return axios.get("/proxyopr/list", { params: param });
}

export function stat(param) {
  return axios.get("/proxyopr/stat", { params: param });
}

export function oprAmount(oprData) {
  if (
    oprData.limitAmount == null ||
    oprData.limitAmount < 0
  ) {
    msg.fail("操作金额应 >= 0");
    return;
  }
  oprData.userId = oprData.id;
  return axios.post("/proxyopr/oprAmount", oprData);
}

export function getProxyInfo() {
  return axios.get("/proxyopr/getProxyInfo", {});
}